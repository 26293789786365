import { z } from 'zod'
import * as zod from 'zod'

import { nicknameRegex } from '../../../utils/settings/profile'

import { createNameSchema } from './name.validation'

export const addressSchema = z.object({
  street_and_number: z
    .string({ errorMap: () => ({ message: 'street_and_number' }) })
    .max(100)
    .optional()
    .or(z.literal('')),
  city: z
    .string({ errorMap: () => ({ message: 'city' }) })
    .max(30)
    .optional()
    .or(z.literal('')),
  company: z.string().max(300).optional(),
  country: z
    .string({ errorMap: () => ({ message: 'country' }) })
    .max(100)
    .regex(/^[a-zA-Z-. ]+$/)
    .optional()
    .or(z.literal('')),
  postal_code: z
    .string({ errorMap: () => ({ message: 'postal_code' }) })
    .max(10)
    .regex(/^[0-9a-zA-Z]+$/)
    .optional()
    .or(z.literal(''))
})

export const createProfileValidationSchema = (nicknameAllowed = false) =>
  createNameSchema().merge(
    z.object({
      avatar_id: z.string().optional(),
      email: z
        .string({ errorMap: () => ({ message: 'email' }) })
        .min(1)
        .email(),
      phone_number: z
        .string()
        .optional()
        .refine(value => !value || /^\+?[0-9\s-]{7,15}$/.test(value), 'phone_number')
        .optional(),
      nickname: nicknameAllowed
        ? z
            .string({ errorMap: () => ({ message: 'nickname' }) })
            .min(3)
            .max(100)
            .regex(nicknameRegex)
        : z.string().optional().or(z.literal('')),
      address: addressSchema
    })
  )

export type ProfileFormData = zod.infer<ReturnType<typeof createProfileValidationSchema>>
