import { createQueryKeys } from '@lukemorales/query-key-factory'

const threadMessages = createQueryKeys('threadMessages', {
  all: (threadId: number, order) => ({
    queryKey: [{ threadId, order }]
  }),

  allCount: {
    queryKey: null
  }
})

export default threadMessages
